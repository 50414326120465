import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {findFreeTimes,generateAllFreeSlot, sortTimesByMorningAndAfternoon}  from '../util'
import { updateBookingInfo } from './bookingReducer'

import moment from 'moment-timezone'

import _ from 'lodash'
moment.tz.setDefault("America/Boise")


const FreeTimes = () => {
    const dispatch = useDispatch()
    const booking =  useSelector(state => state.booking)
    const appointments =  booking.appointments
    const chosenDate =  booking.chosenDate
    const service =  booking.service
    const serviceDuration = _.get(booking,'service.duration')
    const [availableTimesForBooking, setAvailableTimesForBooking] = useState([])
    useEffect(()=>{
      generateAllBookableTimes(chosenDate, appointments)
    },[chosenDate, service])
    
    const generateAllDayFreeSlot = (date) => {
      const eventTime = moment(date)
      const eventStartTime = eventTime
                          // .utc()
                          .clone()
                          .set({year: eventTime.get('year'), month: eventTime.get('month'),date:eventTime.get('date'), hours:8,minute:0,second:0,millisecond:0})
                          .format()
      const eventEndTime = eventTime
                          // .utc()
                          .clone()
                          .set({year: eventTime.get('year'), month: eventTime.get('month'),date:eventTime.get('date'), hours:19,minute:0,second:0,millisecond:0})
                          .format()
      const duration = moment.duration(moment(eventEndTime).diff(eventStartTime)).as('minutes')
  
      const freeTime = [{
        startTime: eventStartTime,
        endTime: eventEndTime,
        duration
      }]
      return freeTime
    }
  
    const generateAllBookableTimes = (date, appointments) => {
      const yearMonth = moment(date).utc().format("YYYY-MM")
      const llDate = moment(date).utc().format("LL")
      const allEventsFromDate = _.get(appointments,`[${yearMonth}][${llDate}]`,[])
      let freeTimes
      if(_.isEmpty(allEventsFromDate)){
        //generateAllDayFreeSlot is a lot like generateAllDayFreeSlot. 
        //if there is no event given for the day, generateAllDayFreeSlot returns
        freeTimes = generateAllDayFreeSlot(date)
      }else{
        freeTimes = findFreeTimes(allEventsFromDate)
      }
      const availableForBooking = generateAllFreeSlot(freeTimes, serviceDuration+30)//takes optional 3rd param interval
      console.log('availableForBooking#@######',  availableForBooking)
      const sortedByTime = sortTimesByMorningAndAfternoon(availableForBooking)
      console.log('sortedByTime#@######',  sortedByTime)
      setAvailableTimesForBooking(sortedByTime)
    }
  
    const onButtonClick = (time) => {
      console.info('button clcicked',time)
      const selectedTime = time
      dispatch(updateBookingInfo({chosenTime:selectedTime}))
    }

    const noFreetime = _.isEmpty(availableTimesForBooking['morning']) && _.isEmpty(availableTimesForBooking['afternoon'])
    const listOfFreeTimes = (

       
      noFreetime ? <h3 class="isa_error">This service is not available for this date </h3> :
      (<div class="row gtr-200">
        <div class="col-12">
          <h3>Please select a time</h3>
        </div>
        
        <div class="col-6 col-12-medium">
          <ul class="actions">
            <li>
              <h4>Morning</h4>
              <ul className='no-list-style'>
                {_.map(availableTimesForBooking['morning'], time => <li id={time}><a className="button primary"  onClick={()=>onButtonClick(time)}>{moment(time).format('HH:mm')}</a></li>)}
              </ul>
            </li>
            <li>
              <div>
                  <h4>Afternoon</h4>
                  <ul className='no-list-style'>
                      {_.map(availableTimesForBooking['afternoon'], time => <li id={time}><a  className="button primary" onClick={()=>onButtonClick(time)}>{moment(time).format('HH:mm')}</a></li>)}
                  </ul>
              </div>
            </li>
          </ul>
      </div>
      </div>)
        
      
      )
    return  listOfFreeTimes
  }

  export default FreeTimes
