import { createSlice } from '@reduxjs/toolkit'
import {map, keys, get} from 'lodash'

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
        appointments: null,
        chosenTime:null,
        chosenDate:null,
        duration:null,
        service:null
  },
  reducers: {
    updateBookingInfo: (state, {payload}) => {
      map(keys(payload), key =>state[key] = get(payload, key) )
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateBookingInfo } = bookingSlice.actions

export default bookingSlice.reducer