import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";      
import Booking from './components/booking/Booking'
import Calendar from './components/booking/Calendar'
import BookingPage from './components/booking/BookingPage'
import Services from './components/services/Services'
import Products from './components/services/Products'

function App() {
  const Body = () => (
    <Router>
      <div>
        <div>
          <Switch>
            <Route path="/booking">
              <Booking />
            </Route>
            <Route path="/Calendar">
               <Calendar/>
            </Route> 
            <Route path="/BookingPage">
                <BookingPage/>
            </Route>
            <Route path="/Services">
                <Services/>
            </Route>
            <Route path="/Products">
                <Products/>
            </Route>
            <Route path="*">
                <Services/>
            </Route>

          </Switch>
        </div>
      </div>
    </Router>
    )
  
  return (
    // <div>
      <div>
        <Body/>
      </div>
    // </div>
  );
}

export default App;
