import _ from 'lodash'
import moment from 'moment-timezone'
//handle situations where events overlap
moment.tz.setDefault("America/Boise")
//handle if events start time happen after 19:00:00 => should cut off at 19 and not even process
export function findFreeTimes (events) {
    // const minTime = "08:00:00"
    // const maxTime = "19:00:00"
    // takes in 
    // - events, a list of event objects where each event has starttime and endtime
    // returns an array of start times that are open for at least duration amount of time(in 10, 15, or 30 minutes interval?) 
    // by default we only look for free spots Mon - Sat between 8AM and 7PM 
    let lastEventEndTime,startingTime,endTime
    const firstEventTime = moment(events[0].start.dateTime)
    lastEventEndTime = firstEventTime
                                .clone()
                                .set({year: firstEventTime.get('year'), month: firstEventTime.get('month'),date:firstEventTime.get('date'), hours:8,minute:0,second:0,millisecond:0})
                                .format()
    const closingTime = firstEventTime
                        .clone()
                        .set({year: firstEventTime.get('year'), month: firstEventTime.get('month'),date:firstEventTime.get('date'), hours:19,minute:0,second:0,millisecond:0})
                        .format()
    const freeSlots = _.map(events, event => {        
        endTime = moment(event.start.dateTime)
        startingTime = moment(lastEventEndTime)
        const duration = moment.duration(endTime.diff(startingTime)).as('minutes')
        if(startingTime.isBefore(endTime)){
        lastEventEndTime=moment(event.end.dateTime).clone()
        return {
            startTime: startingTime.format(),
            endTime: endTime.format(),
            duration
        }
        }else{
            lastEventEndTime=moment(event.end.dateTime)
            //If two consecutive events are booked back to back so that event1's endtime === event2'starttime
            //return null now and filter it later
            return null
        }
    })
    if(lastEventEndTime.isBefore(closingTime)){
        const duration = moment.duration(moment(closingTime).diff(lastEventEndTime)).as('minutes')
        freeSlots.push(  {
            startTime: moment(lastEventEndTime).format(),
            endTime: closingTime,
            duration
        })
    }
    return _.filter(freeSlots)
} 

export function generateAllFreeSlot (events, duration, interval=15) {
    //given a list of free time events, return all valid starting times for duration
    //const interval = 15 //interval between free time slots in minutes
    const allFreeSlots = []
    _.map(events, event => {
        if(duration > event.duration){
            return
        }
        let lastAddedTime = moment(event.startTime)//.utc()
        allFreeSlots.push(lastAddedTime.format())
        while(lastAddedTime.clone().add(interval+duration,'minutes').isSameOrBefore(event.endTime)){
            allFreeSlots.push(lastAddedTime.clone().add(interval,'minutes').format())
            lastAddedTime = lastAddedTime.clone().add(interval,'minutes')
        }
    })
    return allFreeSlots
}

export function sortTimesByMorningAndAfternoon(times) {
    return _.reduce(times, (allTimes, time) => {
        var currentHour = moment(time).format("HH")
        
        if (currentHour < 8){
            allTimes.others.push(time)
        }
        else if (currentHour < 12){
            allTimes.morning.push(time)
        }
        else if (currentHour < 19){
            allTimes.afternoon.push(time)
        }
        return allTimes
    },{morning:[],afternoon:[], others:[]})

}
// export function generateAvailableTimes (groupedEvents, duration) {
//     // const groupedEvents = groupEventsByDate(events)
//     const dailySchedule = _.map(_.keys(groupedEvents), date => { 
//         const duration = 90 
//         const currentDateEvents =  groupedEvents[date]
//         const freeTimes = findFreeTimes(currentDateEvents)
//         const availableFreeTimeSlots = generateAllFreeSlot(freeTimes, duration)
//         return {
//             appointment: currentDateEvents,
//             freeTimes,
//             availableFreeTimeSlots
//         }
//     })
//     return dailySchedule
// }

