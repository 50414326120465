
import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'

const Products = () => {
return (
    <div id="main">
    <div class="wrapper">
        <div class="inner">
            <header class="major">
                <h1>Products</h1>
                <p>In addition to in-house care we provide, you can continute to care for yourself with these products at home!</p>
            </header>
            
            <div class='text_centered'>
                <h2>Glymed Plus</h2>
                <p>If you would like to purchase products from Glymed Plus, please use <a href='https://glymedplus.com/store/eunokkim'> this link</a> or visit <a href='https://glymedplus.com/store/eunokkim'>https://glymedplus.com/store/eunokkim</a></p>
            </div>
            <div class='text_centered'>
                <h2>BSG Aesthetics Solutions</h2>
                <p>If you would like to purchase products from BSG Aesthetics Solutions, please use <a href='https://www.biospecgrp.com/skin-care-products/'> this link</a> or visit <a href='https://www.biospecgrp.com/skin-care-products/'>https://www.biospecgrp.com/skin-care-products/</a></p>
            </div>
            
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item001.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item002.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item003.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item004.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item005.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item006.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item007.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item008.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item009.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item010.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item011.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item012.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item013.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item014.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item015.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item016.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item017.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item018.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item019.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item020.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item021.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item022.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item023.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item024.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item025.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item026.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item027.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item028.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item029.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item030.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item031.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item032.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item033.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item034.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item035.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item036.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item037.jpeg" alt="" /></span>
            <span class="image fit"><img src="https://body-and-face-static.s3.us-west-1.amazonaws.com/item038.jpeg" alt="" /></span>
            
        </div>
    </div>
</div>

)
}

export default Products