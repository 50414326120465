import React,{useState} from "react";
import { useForm } from "react-hook-form";
import { updateBookingInfo } from './bookingReducer'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone';
moment.tz.setDefault("America/Boise")
export default function BookingForm() {
  const [error, setError] = useState()
  const [message, setMessage] = useState()
  const {chosenTime, service} =  useSelector(state => state.booking)

  const duration = service.duration
  const dispatch = useDispatch()
  const { register, handleSubmit,  formState: { errors } } = useForm();
  
  const onSubmit = async data => {
    const form = data
    await createBooking(form)
    return form
  }

  const createBooking = async (details) => {
    const { firstName, lastName } = details
    const startTime = chosenTime
    const endTime = moment(chosenTime).add(duration + 30,'minutes').format() //Owner wanted extra 30 minutes blocked after an appointment is over
    
    const checkAllRequiredData = () => [chosenTime, service, duration, firstName, lastName ].every(field => field)
    if(!checkAllRequiredData()){
      console.error("Not all required fields are selected. Please try again.")
      setError("Not all required information is provided. Please try again.")
      return
    }
    const formatDescription = (details) => `
    service: ${service.name}
    duration: ${duration} minutes
    first name: ${details.firstName}
    last name: ${details.lastName}
    phone number: ${details.mobileNumber}
    email: ${details.email}
    message: ${details.comment}
    `
    try{
      const event = {
        'summary': `${service.name} for ${firstName || ''} ${lastName || ''}`,
        'description': formatDescription(details),
        'start': {
          'dateTime': startTime,
          'timeZone': 'America/Boise',
        },
        'end': {
          'dateTime': endTime,
          'timeZone': 'America/Boise',
        },
        'attendees':[{
          'email': details.email,
          'displayName': `${details.firstName} ${details.lastName}`,
          'optional': false,

        }]
      }
      await fetch(
                'server/event',
                {
                    method:"POST", 
                    body:JSON.stringify(event),
                    headers: { 'Content-Type': 'application/json' }
                }
                )
      //TODO REFRESH After sucessful booking with message
      console.info(`Successfully created booking ${service} for ${firstName || ''} ${lastName || ''}`)
      // TODO clear redux.booking data after successful booking 
      setMessage(`Succesfully booked appointment. Email confirmation has been sent to ${details.email}`)
      return
    }catch(error){
      console.error("Error in createBooking", error)
      setError("Error while trying to make the appointment. Please try again")
    }
  }
  
  const bookingForm =
  <React.Fragment> 
    <form onSubmit={handleSubmit(onSubmit)}>
      <input  type="text" disabled value={`Chosen Time: ${moment(chosenTime).format('HH:mm (YYYY-MM-DD)')}`}/>
      <input required type="text" placeholder="First name" {...register("firstName", {required: true, maxLength: 80})} />
      {errors["firstName"]?.type === 'required' && "First name is required"}
      <input required type="text" placeholder="Last name" {...register("lastName", {required: true, maxLength: 100})} />
      {errors["lastName"]?.type === 'required' && "Last name is required"}
      <input required type="text" placeholder="Email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
      {errors["email"]?.type === 'required' && "Email is required"}
      {errors["email"]?.type === 'pattern' && "Valid email is required"}
      <input required type="tel" placeholder="Mobile number" {...register("mobileNumber", {required: true, minLength: 6, maxLength: 12})} />
      {errors["mobileNumber"]?.type === 'required' && "Mobile number is required"}
      <textarea placeholder="Additional comment" {...register("comment")}/>
      {/* //TODO make the submit button a spinner while submitting */}
      <input type ='button' value="Change Time" onClick={()=>{dispatch(updateBookingInfo({chosenTime: null}))}}/>
      <input type="submit" />
    </form>
    <ul>
      {error && <div class="isa_error">
        <i class="fa fa-times-circle"></i>
        {error}
      </div>
      }
      {message && <div class="isa_success">
        <i class="fa fa-check"></i>
          {message}
          </div>
      }
    </ul>
  </React.Fragment>

  return (service && chosenTime) && bookingForm
}
