import React from 'react'
import { useSelector } from 'react-redux'
import BookingForm from './BookingForm'
import BookableServices from './BookableServices'
import Calendar from './Calendar'
import FreeTimes from './FreeTimes'
import moment from 'moment-timezone';
moment.tz.setDefault("America/Boise")
const BookingPage = () => {
    const booking =  useSelector(state => state.booking)
    return (
        <div id="main">
        <div class="wrapper">
            <div class="inner">
                <header class="major">
                    <h2>Make your next appointment</h2>
                    <p>Is this your first time? We strongly recommend an initial consultation to better meet your needs</p>
                </header>
                <div class="row gtr-200">
                    <div class="col-6 col-12-medium"><Calendar/></div>
                    <div class="col-6 col-12-medium">
                        {booking.chosenDate && !['Saturday', 'Sunday'].includes(moment(booking.chosenDate, 'YYYY-MM-DD').format('dddd')) && <BookableServices/>}
                        {booking.chosenDate && ['Saturday', 'Sunday'].includes(moment(booking.chosenDate, 'YYYY-MM-DD').format('dddd')) && <div><h3>Sorry, Weekends aren't open for booking</h3></div>}
                        {!booking.chosenTime && booking.chosenDate && !['Saturday', 'Sunday'].includes(moment(booking.chosenDate, 'YYYY-MM-DD').format('dddd'))  && booking.service && <FreeTimes/>}
                        {booking.chosenDate && !['Saturday', 'Sunday'].includes(moment(booking.chosenDate, 'YYYY-MM-DD').format('dddd')) && booking.service && booking.chosenTime && <BookingForm/>}
                    </div>
                </div>
            </div>
					</div>
			</div>

    )
}
export default BookingPage