import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import moment from 'moment-timezone';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { updateBookingInfo } from './bookingReducer'
moment.tz.setDefault("America/Boise")
//TOOD: call getEventsFromMonth when user creates an event 
const Calendar = (props) => {
  const dispatch = useDispatch()
  const booking =  useSelector(state => state.booking)
  const appointments =  booking.appointments

  const getEventsFromMonth = async (startTime) => {
    try{
      const yearMonth = moment(startTime).format('YYYY-MM')
      if(_.has(appointments, yearMonth)){
        console.log("Already has data do not retrieve again", appointments)
        return
      }
      const ISOStartTime =moment(startTime).toISOString()
      const res = await fetch(`server/getEventsForMonth?startTime=${ISOStartTime}`)
      const events = await res.json()
      // console.info("Fetched events: ",events )
      dispatch(updateBookingInfo({appointments: {...appointments, [yearMonth]:events}}))
     }catch(error){
      console.error("error happend while fetching events", error)
    }
  }

    const onDateRangeChange = async (evt) => {
      await getEventsFromMonth(evt.startStr)
    }

    const onDateClick = (evt) => {
      dispatch(updateBookingInfo({chosenDate:moment(evt.date).toISOString()}))
    }


    return <React.Fragment>
      <FullCalendar
        plugins={[ timeGridPlugin,dayGridPlugin,interactionPlugin ]}
        initialView="dayGridMonth"
        validRange= {{
          start:moment.now(),
          end: moment().add(6, 'M').format('YYYY-MM-DD') // Can make reservation upto 6months from now. Maybe extend once Phone Verification is added
        }}
        selectable = {true}
        showNonCurrentDates = {false}
        fixedWeekCount = {false}
        datesSet={onDateRangeChange}
        allDaySlot= {false}
        dateClick={onDateClick}
        height='auto'
      />  
  </React.Fragment>
}

export default Calendar