import React, { useEffect } from 'react'
import services from '../ListOfServices.json'
import { useSelector, useDispatch } from 'react-redux'
import { updateBookingInfo } from './bookingReducer'

const BookableServices = (prop) => {
    const dispatch = useDispatch()
    const booking =  useSelector(state => state.booking)
    const selectedService = booking.service 
    useEffect(()=>{
        dispatch(updateBookingInfo({service: selectedService}))
    }, [])
    useEffect(()=>{
        const initializeSelect = () => document.getElementById('bookableServicesSelect').value = JSON.stringify(selectedService);
        initializeSelect()
    },[selectedService])
    

    const formatServiceList = service => `${service.name} - ${service.duration} minutes`
    return (
            <div class="row gtr-uniform">
                 <div class="col-12">
                     <label>{selectedService ?  '' : 'Please select a service'}</label>
                    <select  
                        id='bookableServicesSelect'
                        onChange={(event)=>{
                            const service = JSON.parse(event.target.value)
                            dispatch(updateBookingInfo({service, chosenTime:null}))
                        }} 
                    >
                        {services.map(service =>  
                            <option key={`${service.name}${service.duration}`} value={JSON.stringify(service)}>
                                {formatServiceList(service)}
                            </option>)
                        } 
                    </select>
                </div>
             </div>
  
    )
}

export default BookableServices