import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'

const Services = () => {

// const fetchImages = (imageName) => {
//     // const imageName = 'microblade1.jpg'
//     const imageUrl = `http://localhost:3001/fetchImage/${imageName}`
//     fetch(imageUrl)
//     .then(response => response.blob())
//     .then(imageBlob => {
//         // Then create a local URL for that image and print it 
//         const imageObjectURL = URL.createObjectURL(imageBlob);
//         console.log(imageObjectURL);
//     });

    
//     // axios.get(url, {responseType: 'blob'})
//     // .then(res => {
//     //     return(
//     //         <img src={res.data} alt="trial" />
//     //     )
//     // }
// }
//     fetchImages('microblade1.jpg')
return (
            // <div id="one">
            // 			<div class="wrapper special">
            // 				<div class="inner">
            // 					<header class="major">
            // 						<h2>Amet magna adipiscing</h2>
            // 					</header>
            // 					<div class="image fit special">
            // 						<img src="images/pic02.jpg" alt="" />
            // 					</div>
            // 					<p class="special">Sed egestas, ante et vulputate volutpat pede semper est, vitae luctus metus libero eu augue. Morbi purus libero, faucibus adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent elementum lorem ipsum dolor sit amet consequat hendrerit tortor semper lorem at felis.</p>
            // 					<ul class="actions">
            // 						<li><a href="#" class="button">Details</a></li>
            // 					</ul>
            // 				</div>
            // 			</div>
            // 		</div>

			// <div id="two">
			// 	<div class="wrapper style2">
			// 		<div class="inner">
			// 			<div class="spotlights">
			// 				<div class="intro">
			// 					<header>
			// 						<h2 class="left">Eu ligula<br /> consequat</h2>
			// 						<p>Sed egestas, ante et vulputate volutpat pede semper est, vitae luctus metus libero eu augue. Morbi purus libero, faucibus adipiscing.</p>
			// 					</header>
			// 					<ul class="actions">
			// 						<li><a href="#" class="button">Details</a></li>
			// 					</ul>
			// 				</div>
			// 				<div class="spotlight">
			// 					<div class="image fit">
			// 						<img src="images/pic03.jpg" alt="" />
			// 					</div>
			// 					<div class="content">Magna sed tempus</div>
			// 				</div>
			// 				<div class="spotlight">
			// 					<div class="image fit">
			// 						<img src="images/pic04.jpg" alt="" />
			// 					</div>
			// 					<div class="content">Lorem ipsum dolor</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>

            <div id="three">
            <div class="wrapper alt special">
                    {/* microblading */}
                {/* <header class="major">
                    <h2>Microblading</h2>
                    <p>This can be used in conjunction with other alternative therapies. It takes into account the whole person and helps activate each patient’s natural healing processes as well as restoring their physical and emotional well-being.</p>
                    <ul class="actions">
                        <li><a href="/bookingpage" class="button">Book Now!</a></li>
                    </ul>
                </header> */}
                    {/* Facials */}
                    <header class="major">
                        <h2>Facial Care</h2>
                        <p>This is a hands-on body treatment that leaves patients feeling relaxed and renewed. I love showing my patients how just a few treatments can make an enormous difference to their health.</p>                   
                    </header>
                    <div class="inner">
                        <div class="profiles">
                            <div class="profile">
                                {/* <div class="image">
                                    <img src="http://localhost:3001/fetchImage/microblade1.jpg" alt="" />
                                </div> */}
                                <div class="content">
                                    <h3>Signature Facial</h3>
                                    <p>
                                        This service will be customized for individual clients based on consultation<br/>
                                        1 hr 30 min <br/>
                                        Starting at $140
                                    </p>
                                </div>
                            </div>
                            <div class="profile">
                                <div class="content">
                                    <h3>European Facial with Deep Exfoliation</h3>
                                    <p>
                                    For those that want a little more than the European Facial, the Premium European Facial adds on a back, arms, and hands massage!
The European facial very essence of good skin care. It includes cleansing, steaming, exfoliation, extraction (if necessary), a light massage, and individualized mask to soothe, repair and revitalize.<br/>
                                        60 min <br/>
                                        $100
                                    </p>
                                </div>
                            </div>
                            <div class="profile">
                                <div class="content">
                                    <h3>Korean Style Facial</h3>
                                    <p>
                                    For clients that enjoy a good shoulder and back massage with their facials, this is the one for you! This is catered to you to get the most out of your facial. It contains décolleté massaging to stimulate the lymphatic system to get rid of toxins while paired with steaming marine collagen gel to make your skin lighter and brighter.
This facial is recommended for those who suffer from TMJ or jaw issues, or overly dry skin.<br/>
                                        1 hr 10 min <br/>
                                        $130
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class='profiles'>
                            <div class="profile">
                                <div class="content">
                                    <h3>Collagen Facial</h3>
                                    <p>
                                    A collagen facial has the perfect balance of using water and ultra sound technology to remove dead skin cells, promote collagen and elastin production. After your skin is prepared, it receives a marine collagen mask to help your skin find its perfect balance.
This facial is beneficial for those with rosacea, excema, oily/dry and redness combo skin.<br/>
                                        1 hr 10 min <br/>
                                        $150
                                    </p>
                                </div>
                            </div>
                        <div class="profile">
                                <div class="content">
                                    <h3>Dermaplaning Facial</h3>
                                    <p>
                                    Dermaplaning is an exfoliation technique to remove debris by using a scalpel, which allows greater absorption of other topical treatments. Also, it aids with diminishing appearance of fine lines and wrinkles by stimulating the collagen in the skin. This treatment includes a single-layer, chemical peel for optimal results.<br/>
                                        60 min <br/>
                                        $135
                                    </p>
                                </div>
                            </div>
                            <div class="profile">
                                <div class="content">
                                    <h3>O2 to Derm Plus LED</h3>
                                    <p>
                                    This premium skin care improves skin condition by maximizing skin metabolism by supplying oxygen and anion at the same time.

O2toDerm therapy delivers higher concentrations of oxygen (>90%) to the skin, making skin respiration better, rejuvenating the skin, and achieving anti-aging results. It is improves the skin's ability to reproduce collagen to improve signs of aging. It eliminates ROS aka Reactive Oxygen Species, which is primary source of Aging. (<a target="_blank" rel="noreferrer"  href='https://www.biotek.com/resources/white-papers/an-introduction-to-reactive-oxygen-species-measurement-of-ros-in-cells'>read more</a>)<br/>
                                        60 min <br/>
                                        $160
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div class='profiles'>
                        <div class="profile">
                                <div class="content">
                                    <h3>Oxygen Treatment</h3>
                                    <p>Used With O2 to Derm Products </p>
                                    <p>
                                        This treatment helps with regeneration of new cells, reduce bacteria, rebalances pH levels, improves signs of aging, and corrects and heals damaged cells.<br/>
                                        1 hr 30 min <br/>
                                        $170
                                    </p>
                                </div>
                            </div>
                            <div class="profile">
                                <div class="content">
                                    <h3>Just Relaxing BF</h3>
                                    <p>
                                        Relaxing body and European Facial with hydrating aroma oil and foot massage with warm towel.<br/>
                                        1 hr 30 min <br/>
                                        $150
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ul class="actions">
                        <li><a href="/bookingpage" class="button">Book Now!</a></li>
                    </ul>
                    </div>

                    <div class="wrapper style3">                           
                        <header class="major">
                            <h2>Microchanneling</h2>
                            <p>
                                        Microneedling, but even better! This treatment helps reduce the appearance of fine lines and wrinkles, acne, acne scars, stretch marks, uneven skin complexion, and sagging skin. Clients may need more than two treatments.<br/>
                                            1 hr 30 min <br/>
                                            Starting at $335
                                        </p>
                            <ul class="actions">
                                <li><a href="/bookingpage" class="button">Book Now!</a></li>
                            </ul>
                        </header>
                    </div>


                    <header class="major">
                        <h2>Body and Face Treatments</h2>
                        <p>Refreshing services that focus on both body and face</p>                   
                    </header>
                    <div class="inner">
                            <div class="profiles">
                                <div class="profile">
                                    <div class="content">
                                        <h3>Body and Face Contouring</h3>
                                        <p>
                                        Body exfoliative treatment and contouring cream with niacin and cinnamon, and body wrap. Your face will be pampered with a customizable essential oil, and pressure acupoint to stimulate blood flow to remove toxins. Your skin will have a slimmer, firmer tone, and smoother appearance.<br/>
                                            1 hr 30 min <br/>
                                            $210
                                        </p>
                                    </div>
                                </div>
                                <div class="profile">
                                    <div class="content">
                                        <h3>Just Relaxing BF</h3>
                                        <p>
                                        Relaxing body and European Facial with hydrating aroma oil and foot massage with warm towel.<br/>
                                            1 hr 30 min <br/>
                                            $150    
                                        </p>
                                    </div>
                                </div>
                                <div class="profile">
                                    <div class="content">
                                        <h3>Ultimate Indulgence</h3>
                                        <p>
                                        Exfoliate your skin with our special blend of aromatic oils: A hot stone massage: a classical facial (with Derma sound or Ultrasonic) with marine collagen: and foot reflexology with body Wrap or/and skull massage.<br/>
                                            1 hr 30 min <br/>
                                            $210  
                                        </p>
                                    </div>
                                </div>
                                <div class="profile">
                                    <div class="content">
                                        <h3>Body Scrubs</h3>
                                        <p>
                                        A sugar scrub which contains natural oils that moisturize and nourish your skin, along with a body wrap.<br/>
                                            60 min <br/>
                                            $90
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <ul class="actions">
                            <li><a href="/bookingpage" class="button">Book Now!</a></li>
                        </ul>
                </div>


       
                <div class="wrapper style3">
                <header class="major">
                    <h2>Waxing and Tinting</h2>
                    <p>
                    Full Face $55<br/>
                    Eye Brow Wax $25 (with Eye Brow Tint $35)<br/>
                    Eye Lash Tint $27<br/>
                    Lips $22<br/>
                    Chin $22<br/>
                    3D Eye Lash Lift with Tint $95<br/>
                    </p>
                    <ul class="actions">
                        <li><a href="/bookingpage" class="button">Book Now!</a></li>
                    </ul>
                    
                </header>
                </div> 

                <div class="wrapper alt special">

                
                    <header class="major">
                        <h2>Massages</h2>
                    </header>
                    <div class="inner">
                        <div class="profiles">
                            <div class="profile">
                                <div class="content">
                                    <h3>Therapeutic Massage</h3>
                                    <p>
                                        Massage that is catered to your needs! This is recommended for those who suffer from sciatic nerve, shoulder, scoliosis, neck, fibromyalgia, trauma from injury after surgery (i.e. knee surgery, car accident), or sinus issues. If needed, hot stone and cupping provided.<br/>
                                        60 min <br/>
                                        Starting at $100
                                    </p>
                                </div>
                            </div>
                            <div class="profile">
                                <div class="content">
                                    <h3>Lymphatic Massage with Cupping</h3>
                                    <p>
                                    This treatment has lymph drain that includes cupping, stretching and acupressure.
Cupping helps increase blood flow and promote new cell growth. It relaxes the fascia to improve appearance of cellulite, and relieves muscle pain.
<br/>
                                        90 min <br/>
                                        $190
                                    </p>
                                </div>
                            </div>

                        </div>
                        <ul class="actions">
                        <li><a href="/bookingpage" class="button">Book Now!</a></li>
                    </ul>
                    </div>
                </div>
                

                <div class="wrapper style3">
                <header class="major">
                        <h2>Lymphedema Therapy</h2>
                        <p><a target="_blank" rel="noopener noreferrer"  href="https://www.mayoclinic.org/diseases-conditions/lymphedema/symptoms-causes/syc-20374682">Lymphedema</a>, or lymphatic obstruction, is a long- term condition where excess fluid collects in tissues causing swelling (edema). Lymphedema is most commonly caused by the removal of or damage to lymph nodes as apart of cancer treatment or surgery.<br/>
                        This service includes Lymph drainage massage to move waste fluid away from the damaged area and compression therapy to prevent further fluid build-ups.</p>
                        <ul class="actions">
                            <li><a href="/bookingpage" class="button">Book Now!</a></li>
                        </ul>
                    </header>
                </div>
            </div>
        </div>
)
}

export default Services